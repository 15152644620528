function Card({ title, children, actions }) {
  return (
    <div className="bg-white w-full max-w-96 h-[650px] px-2 py-4 rounded-xl flex flex-col gap-4">
      <div id="title" className="font-bold text-2xl text-center">
        {title}
      </div>
      <div id="content" className="bg-bright-gray p-2 rounded-xl flex-grow">
        {children}
      </div>
      <div id="actions" className="text-center font-bold py-2">
        {actions}
      </div>
    </div>
  );
}

export default Card;