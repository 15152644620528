import './ListItem.css';

function ListItem({item, onClick}) {
  const {name} = item;

  return (
    <div className="item-container" onClick={onClick}>
      <div className="item">
        {name.slice(0, 1)}
      </div>

      {name}
    </div>
  )
}

export default ListItem;