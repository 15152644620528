import Card from './components/Card';
import Separator from './components/Separator';
import { useState } from 'react';
import ListItem from './components/ListItem/ListItem';
import List from './components/List';
import Button from './components/Button';

function App() {
  const [items, setItems] = useState([
    { id: 1, name: 'John Scot' },
    { id: 2, name: 'Vanessa Rail' },
    { id: 3, name: 'Raul Mill' },
    { id: 4, name: 'Mike underwood' },
    { id: 5, name: 'Ronald Flex' },
  ]);

  const [selectedItems, setSelectedItems] = useState([]);

  return (
    <main className='w-full h-full bg-cool-grey flex md:flex-row flex-col items-center justify-center gap-12 p-8'>
      <Card title='Item List' actions={(<Button onClick={()=> {
        setSelectedItems([...selectedItems, ...items]);
        setItems([]);
      }}>Select All</Button>)}>
        <List>
          {items.map((item) => (
            <ListItem onClick={() => {
              setSelectedItems([...selectedItems, item]);
              setItems(items.filter(i => i.id !== item.id));
            }} key={item.id} item={item} />
          ))}
        </List>
      </Card>

      <div>
        <Separator />
      </div>

      <Card title='Selected Items' actions={(<Button onClick={()=> {
        setItems([...selectedItems, ...items]);
        setSelectedItems([]);
      }}>Clear</Button>)}>
        <List>
          {selectedItems.map((item) => (
            <ListItem onClick={() => {
              setItems([...items, item]);
              setSelectedItems(selectedItems.filter(i => i.id !== item.id));
            }} key={item.id} item={item} />
          ))}
        </List>
      </Card>
    </main>
  );
}

export default App;
